import { log } from "console";
import moment from "moment";
import { ConstantsService } from 'src/app/_core/services/constants.services';

export interface IBackendWorkOrderItemLog{
	id: number;
	action: number;
	status: number;
	item_id: number;
	created_by: number;
	extra_value: string;
	action_text: string; //phantom variable
	log_by: string; //phantom variable
	created_at: Date;
	updated_at: Date;
}

export interface IWorkOrderItemLog {
	id        : number;
	action    : number;
	status    : number;
	itemId    : number;
	createdBy : number;
	extraValue: string;
	actionText: string;
	logBy     : string;
	createdAt : Date;
	updatedAt : Date;
	statusText: string;
}

export class WorkOrderItemLog implements IWorkOrderItemLog{
	static readonly clean = Object.freeze(new WorkOrderItemLog());
	id = 0;
	action = 0;
	status = 0;
	itemId = 0;
	createdBy = 0;
	extraValue = '';
	actionText = '';
	logBy = '';
	createdAt : Date;
	updatedAt : Date;
	statusText = '';

	parse(obj: IBackendWorkOrderItemLog) {

		const offset = Number(new Date().getTimezoneOffset() / 60);
		//this.createdAt	= 		 obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : Note.clean.createdAt;
		//this.updatedAt	= 		 obj.updated_at ? moment(obj.updated_at).subtract(offset, 'hours').toDate() : Note.clean.updatedAt;

		this.id       	= Number(obj.id          || WorkOrderItemLog.clean.id      );
		this.action  	= Number(obj.action      || WorkOrderItemLog.clean.action );
		this.status   	= Number(obj.status      || WorkOrderItemLog.clean.status  );
		this.itemId   	= Number(obj.item_id     || WorkOrderItemLog.clean.itemId  );
		this.createdBy  = Number(obj.created_by  || WorkOrderItemLog.clean.createdBy);
		this.actionText = String(obj.action_text || WorkOrderItemLog.clean.actionText);
		this.logBy   	= String(obj.log_by      || WorkOrderItemLog.clean.logBy  );

		this.createdAt	= obj.created_at ? moment(obj.created_at).toDate() : WorkOrderItemLog.clean.createdAt;
		this.updatedAt	= obj.updated_at ? moment(obj.updated_at).toDate() : WorkOrderItemLog.clean.updatedAt;
		this.extraValue = String(obj.extra_value || WorkOrderItemLog.clean.extraValue);

		return this;
	}

	set(obj: IWorkOrderItemLog) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = WorkOrderItemLog.clean[key];
		});

		return this;
	}

	clone() {
		return new WorkOrderItemLog().set(this);
	}

	setStatusText(constantsService: ConstantsService): string {
		if (this.action === 1) {
			const statusOption = constantsService.receivedOptions.find(option => option.value === this.status);
			return statusOption ? statusOption.text : '';
		}else if (this.action === 2) {
			const statusOption = constantsService.workOrderPoStatusOptions.find(option => option.value === this.status);
			return statusOption ? statusOption.text : '';
		}else if (this.action === 3) {
			return this.extraValue;
		}
		return '';
	}

}
