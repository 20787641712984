/* eslint-disable no-mixed-spaces-and-tabs */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Equipment } from '../../models/equipment.model';
import { Squawk } from '../../models/squawk.model';
import moment from 'moment';
import { FaaCourseType } from '../../models/faa-course-type.model';
import { Permissions } from '../../models/permissions.model';
import { ILeasebackFormSection, IStatementform, ITierform } from 'src/app/wrapper/equipment/single-equipment/leaseback/leaseback.component';
import { Leaseback } from '../../models/leaseback.model';
import { Statement } from '../../models/statement.model';
import { ICredentialHeader } from 'src/app/wrapper/equipment/single-equipment/single-equipment-requirements/single-equipment-requirements.component';
import { Requirement } from '../../models/requirement.model';
import { CredentialHistory, IBackendCredentialHistory } from '../../models/credential-history.model';
import { HelpersService } from '../helpers.service';
import { Assets } from '../../models/assets.model';
import { WorkOrder } from 'src/app/_core/models/work-order/work-order.model';
import { GeneralOptions } from '../../models/equipment';

@Injectable({
	providedIn: 'root'
})
export class EquipmentService {
	apiUrl = environment.apiUrl;
	generalOptions$: BehaviorSubject<GeneralOptions> = new BehaviorSubject<GeneralOptions>(null);
	updateOptions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	equipmentList$: BehaviorSubject<{
		equipmentList: Equipment[],
		timeRequested: Date
	}> = new BehaviorSubject<{
		equipmentList: Equipment[],
		timeRequested: Date
	}>(null);
	equipmentTimeFrames$: BehaviorSubject<{
		equipmentTimeFrames: any[],
		timeRequested: Date
	}> = new BehaviorSubject<{
		equipmentTimeFrames: any[],
		timeRequested: Date
	}>(null);
	offset: number;


	constructor(
		private _http: HttpClient,
		private _helpers: HelpersService,
	) {
		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		 this.offset = Number(new Date().getTimezoneOffset() / 60);
	}

	// -------------------------------------------------- ANCHOR: EQUIPMENT


	/**
	 * @param equipment saving equipment object
	 * @returns an object of parameters used to create/edit an Equipment
	 */
	defineEquipmentParams(equipment: Equipment): any {
		//  console.log(`equipment --> `, JSON.parse(JSON.stringify(equipment)));

		let params: any = {};
		params = {
			equipment_type_id: equipment.equipmentTypeId,
			equipment_class_id: equipment.equipmentClassId,
			equipment_subclass_id: equipment.equipmentSubclassId,
			manufacturer: equipment.manufacturer,
			model: equipment.model,
			tail_number: equipment.tailNumber,

			hobbs_field_count : equipment.hobbsFieldCount,
			hobbs1            : Number(equipment.hobbs1),
			hobbs2            : Number(equipment.hobbs2),
			tach_field_count  : equipment.tachFieldCount,
			tach1             : Number(equipment.tach1),
			tach2             : Number(equipment.tach2),
			owned_by_external : String(equipment.ownedByExternal || 'No'),
			owner_id          : Number(equipment.ownerId?.['value'] || equipment.ownerId || 0 ),
		};


		if ( equipment.maintenanceTrackedBy  ) { params.maintenance_tracked_by  = equipment.maintenanceTrackedBy ; }
		                                         params.empty_weight            = equipment.emptyWeight          ;
		                                         params.moment                  = String(equipment.moment)       ;
		                                         params.ttaf                    = equipment.ttaf                 ;
		                                         params.ttaf_start              = equipment.ttafStart            ;
		                                         params.engine_smoh             = equipment.engineSmoh           ;
		                                         params.ett                     = equipment.ett                  ;
		if ( equipment.engineSerialNumber    ) { params.engine_serial_number    = equipment.engineSerialNumber   ; }
		if ( equipment.serialNumber          ) { params.serial_number           = equipment.serialNumber         ; }
		if ( equipment.engineModel           ) { params.engine_model            = equipment.engineModel          ; }
		                                         params.propeller_smoh          = equipment.propellerSmoh        ;
		                                         params.ptt                     = equipment.ptt                  ;
		                                         params.propeller_serial_number = equipment.propellerSerialNumber;
		if ( equipment.propellerModel        ) { params.propeller_model         = equipment.propellerModel       ; }
		                                         params.hourly_rate             = equipment.hourlyRate           ;
		                                         params.fuel_burn_per_hour      = equipment.fuelBurnPerHour      ;

		params.currency = Number(Boolean(equipment.currency));
		if ( equipment.numberDays    ) { params.number_days       = equipment.numberDays;    }
		if ( equipment.numberFlights ) { params.number_flights    = equipment.numberFlights; }
		if ( equipment.numberHobbs   ) { params.number_hobbs      = equipment.numberHobbs;   }
		if ( equipment.takeOffsDay   ) { params.number_take_day   = equipment.takeOffsDay;   }
		if ( equipment.takeOffsNight ) { params.number_take_night = equipment.takeOffsNight; }
		if ( equipment.landingsDay   ) { params.number_land_day   = equipment.landingsDay;   }
		if ( equipment.landingsNight ) { params.landings_nights   = equipment.landingsNight; }

		if ( equipment.simulatorTypeId       ) { params.simulator_type_id      = equipment.simulatorTypeId                                    ; }
		if ( equipment.governingApprovalId   ) { params.governing_approval_id  = equipment.governingApprovalId                                ; }
		if ( equipment.loaExpirationDate     ) { params.loa_expiration_date    = moment(equipment.loaExpirationDate).add(this.offset, 'hours').format('YYYY-MM-DD') ; }
		if ( equipment.loaDocument           ) { params.loa_document_path      = equipment.loaDocument                                        ; }
		if ( equipment.engineManufacturer    ) { params.engine_manufacturer    = equipment.engineManufacturer                                 ; }
		if ( equipment.propellerManufacturer ) { params.propeller_manufacturer = equipment.propellerManufacturer                              ; }

		if( equipment.location){ params.location = equipment.location; }
		//console.log( `defineEquipmentparams --> `, params );
		return params;
	}

	saveNewEquipment(equipment: Equipment) {
		return this._http.post(this.apiUrl + `equipments`, this.defineEquipmentParams(equipment)).pipe(
			map(( data: any )  => {
				// console.log( `saveNewEquipment data --> `, data );
				return {
					equipment: new Equipment().parse(data.data || {})
				};
			}),
		);
	}

	updateEquipment(equipment: Equipment) {
		return this._http.put(this.apiUrl + `equipments/${equipment.id}`, this.defineEquipmentParams(equipment)).pipe(
			map(( data: any )  => {
				// console.log( `updateEquipment data --> `, data );
				return {
					equipment: new Equipment().parse(data.data || {})
				};
			}),
		);
	}

	/**
	 * @param page
	 * @param perPage
	 * @param search
	 * @param start
	 * @param end
	 * @param eventId
	 * @param all If true, returns not active equipments also
	 * @returns A list of Equipment
	 */
	getEquipments(
		page: number,
		perPage: number,
		search ?: string,
		start  ?: string,
		end    ?: string,
		eventId?: number,
		all = false,
		location?: number
	) {
		let params = [
			`page=${ page }`,
			`perPage=${ perPage }`,
		].join('&');
		params = search  ? params + '&' + 'search='   + search  : params;
		params = start   ? params + '&' + 'start='    + start   : params;
		params = end     ? params + '&' + 'end='      + end     : params;
		params = eventId ? params + '&' + 'event_id=' + eventId : params;
		params = all     ? params + '&' + 'all=1'               : params;

		if(location !== undefined){
			params = params + '&location='   + location;
		}

		// console.log( `getEquipments params --> `, params );
		return this._http.get(this.apiUrl + `equipments?` + params).pipe(
			map(( data: any )  => {
				// console.log( `getEquipments data --> `, data );
				return {
					equipments: (data.data.equipments as any[] || []).map((item) => new Equipment().parse(item)),
					total: Number( data.data.total || 0 ),
					timeframes: (data.data.timeframes as any[] || [])
				};
			}),
		);
	}

	getEquipmentsAll(
		page: number,
		perPage: number,
		all = false,
		search ?: string,
		start  ?: string,
		end    ?: string,
	) {
		let params = [
			`page=${ page }`,
			`perPage=${ perPage }`,
		].join('&');
		params = search  ? params + '&' + 'search='   + search  : params;
		params = start   ? params + '&' + 'start='    + start   : params;
		params = end     ? params + '&' + 'end='      + end     : params;
		params = all     ? params + '&' + 'all=1'               : params;
		// console.log( `getEquipments params --> `, params );
		return this._http.get(this.apiUrl + `equipments?` + params).pipe(
			map(( data: any )  => {
				// console.log( `getEquipments data --> `, data );
				return {
					equipments: (data.data.equipments as any[] || []).map((item) => new Equipment().parse(item)),
					total: Number( data.data.total || 0 ),
					timeframes: (data.data.timeframes as any[] || [])
				};
			}),
		);
	}

	getSingleEquipment(equipmentId: number) {
		return this._http.get(this.apiUrl + `equipments/` + equipmentId).pipe(
			map(( data: any )  => {
				return {
					equipment: new Equipment().parse(data.data || {})
				};
			}),
		);
	}

	getGeneralOptions() {
		return this._http.get(this.apiUrl + `options`).pipe(
			map(( data: any )  => {
				return {
					approachTypes       : data.data && data.data.approach_types        ? data.data.approach_types        : [],
					cancelTypes         : data.data && data.data.cancel_types          ? data.data.cancel_types          : [],
					userResidencies     : data.data && data.data.user_residencies      ? data.data.user_residencies      : [],
					clientTypes         : data.data && data.data.client_types          ? data.data.client_types          : [],
					clientSubTypes      : data.data && data.data.client_subtypes       ? data.data.client_subtypes       : [],
					equipmentTypes      : data.data && data.data.equipment_types       ? data.data.equipment_types       : [],
					equipmentSubTypes   : data.data && data.data.equipment_sub_types   ? data.data.equipment_sub_types   : [],
					equipmentClasses    : data.data && data.data.equipment_classes     ? data.data.equipment_classes     : [],
					equipmentSubclasses : data.data && data.data.equipment_sub_classes ? data.data.equipment_sub_classes : [],
					lessonTypes         : data.data && data.data.syllabus_lesson_types ? data.data.syllabus_lesson_types : [],
					roles               : data.data && data.data.roles                 ? data.data.roles                 : [],
					faaCourseTypes      : data.data && data.data.faa_course_types      ? (
						(data.data.faa_course_types as any[] || []).map((item) => new FaaCourseType().parse(item))
					): [],
					reasonForNoFlight   : [
						{id:0, name: 'Exceeded funding'},
						{id:1, name: 'Blackboard inactivity'},
						{id:2, name: 'Semester ended'},
						{id:3, name: 'Other'},
					],
					settings			: {
						startHour  : data.data.settings.start_hour 	|| 0,
						endHour    : data.data.settings.end_hour	|| 24,
						ctaClientId: data.data.settings.cta_client_id || '',
						ctaSecret  : data.data.settings.cta_secret 	|| '',
						balanceLimit  : data.data.settings.balance_limit 	|| 0
					},
					permissions: new Permissions().parse(data?.data?.permissions || undefined),
					locations: data.data && data.data.locations                 ? data.data.locations                 : [],
					user_location: data.data && data.data.user_location                 ? data.data.user_location                 : 1,
				};
			}),
		);
	}

	/** Enables/disables selected user */
	disableEquipment(equipment: Equipment) {
		const params = {
			is_active     : Number(!equipment.isActive     ),
			tail_number   : String( equipment.tailNumber   ),
			serial_number : String( equipment.serialNumber ),
		};
		return this._http.put(this.apiUrl + `equipments/${equipment.id}`, params)
			.pipe(map((data: any) => {
				const updatedEquipment = new Equipment().parse(data.data || {});
				return {
					updatedEquipment
				};
			}));
	}

	getLogHistory(equipmentId: number, credentialId: number) {
		return this._http.get(this.apiUrl + `equipments/${equipmentId}/requirements/${credentialId}/history`)
			.pipe(map((data: any) => {
				//return (data.data as IBackendCredentialHistory[] || []).map(item => new CredentialHistory().parse(item));
				return (data.data as IBackendCredentialHistory[] || []).map(item => new CredentialHistory().parse(item))
			}));
	}


	// -------------------------------------------------- ANCHOR: SQUAWKS

	getSingleSquawk(squawkId: number) {
		return this._http.get(this.apiUrl + `squawks/` + squawkId).pipe(
			map(( data: any )  => {
				return {
					squawk: new Squawk().parse(data.data || {})
				};
			}),
		);
	}

	getSquawkList(isResolved: number, equipmentId?: number, eventId?: number) {
		let params = [
			`page=1`,
			`perPage=999999`,
		].join('&');
		params = equipmentId ? params + '&equipment_id=' + equipmentId : params;
		params = eventId     ? params + '&event_id='     + eventId     : params;
		params = isResolved  ? params + '&is_resolved='  + isResolved  : params;

		return this._http.get(this.apiUrl + `squawks?` + params).pipe(
			map(( data: any )  => {
				return {
					squawks: (data.data.squawks as any[] || []).map((item) => new Squawk().parse(item)),
					total: Number( data.total || 0 ),
				};
			}),
		);
	}

  getSquawkListAll() {
		let params = [
			`page=1`,
			`perPage=999999`,
		].join('&');

		return this._http.get(this.apiUrl + `squawks?` + params).pipe(
			map(( data: any )  => {
        	//console.log(data);
				return {
					squawks: (data.data.squawks as any[] || []).map((item) => new Squawk().parse(item)),
					total: Number( data.total || 0 ),
				};
			}),
		);
	}

	createSquawk(squawk: Squawk) {
		const params: any = {};

		if ( squawk.description    ) { params.description       = squawk.description   ; }
		if ( squawk.equipmentId    ) { params.equipment_id      = squawk.equipmentId   ; }
		if ( squawk.eventId        ) { params.event_id          = squawk.eventId       ; }
		if ( squawk.isResolved     ) { params.is_resolved       = squawk.isResolved    ; }
		if ( squawk.requiredToFly  ) { params.required_to_fly   = squawk.requiredToFly ; }
		if ( squawk.resolvedBy     ) { params.resolved_by       = squawk.resolvedBy    ; }
		if ( squawk.typeOfSquawk   ) { params.type_of_squawk    = squawk.typeOfSquawk  ; }
		if ( squawk.typeOfIssue    ) { params.type_of_issue     = squawk.typeOfIssue   ; }
		if ( squawk.subTypeOfIssue ) { params.sub_type_of_issue = squawk.subTypeOfIssue; }

		return this._http.post(this.apiUrl + `squawks`, params).pipe(
			map(( data: any )  => {
				return {
					squawk: new Squawk().parse(data.data || {})
				};
			}),
		);
	}

	updateSquawk(squawk: Squawk, unresolve = false) {
		const params: any = {};

		if ( squawk.actions        ) { params.actions           = squawk.actions       ; }
		if ( squawk.description    ) { params.description       = squawk.description   ; }
		if ( squawk.equipmentId    ) { params.equipment_id      = squawk.equipmentId   ; }
		if ( squawk.eventId        ) { params.event_id          = squawk.eventId       ; }
		if ( squawk.isResolved     ) { params.is_resolved       = unresolve ? 0 : squawk.isResolved    ; }
		if ( squawk.issuedBy       ) { params.issued_by         = squawk.issuedBy      ; }
		if ( squawk.requiredToFly  ) { params.required_to_fly   = squawk.requiredToFly ; }
		if ( squawk.resolvedBy     ) { params.resolved_by       = squawk.resolvedBy    ; }
		if ( squawk.typeOfSquawk   ) { params.type_of_squawk    = squawk.typeOfSquawk  ; }
		if ( squawk.typeOfIssue    ) { params.type_of_issue     = squawk.typeOfIssue   ; }
		if ( squawk.subTypeOfIssue ) { params.sub_type_of_issue = squawk.subTypeOfIssue; }
		if ( squawk.notes ) { params.notes = squawk.notes; }

		return this._http.put(this.apiUrl + `squawks/` + squawk.id, params).pipe(
			map(( data: any )  => {
				return {
					squawk: new Squawk().parse(data.data || {})
				};
			}),
		);
	}

	deleteSquawk(squawkId: number) {
		return this._http.delete(this.apiUrl + `squawks/${squawkId}`).pipe(
			map(( data: any )  => {
				return {data};
			}),
		);
	}

	replaceTach(equipmentId: number, previousTach: number) {
		const params: any = {};
		params.equipment_id = equipmentId;
		params.previous_tach_meter = previousTach;

		return this._http.post(this.apiUrl + `equipments/${equipmentId}/replacetach`, params).pipe(
			map(( data: any )  => {
				const updatedEquipment = new Equipment().parse(data.data || {});
				return {
					updatedEquipment
				};
			}),
		);
	}

	// -------------------------------------------------- ANCHOR: REQUIREMENTS

	getEquipmentCredentials(equipmentId: number, header?: ICredentialHeader, all = false) {
		let url = this.apiUrl + `equipments/${equipmentId}/credentials`;
		if (header && header.sorted !== 'none') {
			const column = (
				header.name === 'Days remaining' ? 'days' :
					header.name === 'Time Remaining' ? 'time' :
						header.name === 'Status' ? 'status' : null
			);
			url += `?sort=${column}&sort_mode=${header.sorted}`;
		}

    if(all){
		url += `?all=1&offset=${this.offset}`;
    }
    //console.log("header2: ",header);
		return this._http.get(url).pipe(
			map((data: any) => {
				//console.log(`getEquipmentCredentials data`, data);
				return {
					credentials: (data?.data as any[] || []).map((item) => new Requirement().parse(item)),
					workorders: (data?.workorders as any[] || []).map((item) => new WorkOrder().parse(item)),
					logbooks: (data?.logbooks as any[] || [])
				};
			})
		);
	}

	// POST equipments/:id/requirements/:requirement_id/resolve

	resolveEquipmentRequirement(equipmentId:number, requirementId:number, params: any) {
		return this._http.post(this.apiUrl + `equipments/${equipmentId}/requirements/${requirementId}/resolve`, params).pipe(
			map(( data: any )  => {
				return {
					data
				};
			}),
		);
	}

	addRequirementsToEquipment(equipmentId: number, ids: number[]) {
		return this._http.post(this.apiUrl + `equipments/${equipmentId}/requirements`, {requirement_ids: ids}).pipe(
			map(( data: any )  => {
				return {
					data
				};
			}),
		);
	}

	disableCredentialForEquipment(
		equipmentId: number,
		isEnabled: boolean,
		credentialId: number,
		pinNumber: number
	) {
		const params = {
			is_enabled: Number(isEnabled),
			pin: this._helpers.updatePin(pinNumber),
		};

		return this._http.put(this.apiUrl + `equipments/${equipmentId}/requirements/${credentialId}`, params)
			.pipe(map((data: any) => {
				return data;
			}));
	}

	unresolveCredentialForEquipment(
		equipmentId: number,
		credentialId: number,
		pinNumber: number
	) {
		const params = {
			pin: this._helpers.updatePin(pinNumber),
		};
		return this._http.post(this.apiUrl + `equipments/${equipmentId}/requirements/${credentialId}/unresolve`, params)
			.pipe(map((data: any) => {
				return data;
			}));
	}

	updateEquipmentOrder(equipments: {id: number, custom_order: number}[]): Observable<any> {
		return this._http.put(`${this.apiUrl}equipments/order/update`, {
			equipment: equipments
		});
	}


	// -------------------------------------------------- ANCHOR: LEASEBACKS

	getLeaseback(equipmentId: number) {
		return this._http.get(this.apiUrl + `equipments/${equipmentId}/leasebacks`).pipe(
			map((data: any) => {
				// console.log(`getLeaseback data --> `, data);
				return {
					leaseback: new Leaseback().parse(data.data || {})
				};
			})
		);
	}

	saveLeaseback(
		equipmentId: number,
		settingSections: ILeasebackFormSection[],
		formValue: any,
		tiers: ITierform[],
		statements: IStatementform[]
	) {
		// console.group(`saveLeaseback`);
		// console.log(`equipmentId     `, equipmentId);
		// console.log(`settingSections `, settingSections);
		// console.log(`formValue       `, formValue);
		// console.log(`tiers           `, tiers);
		// console.log(`statements      `, statements);

		const params: any = {
			tiers                      : [],
			statements                 : [],
			insurance_monthly_payments : [],
		};

		const insuranceMonthlyPayments = [];
		settingSections.forEach( section => {
			section.formInputs.forEach( input => {

				/**
				 * Defining all params with backendName (non-dynamic)
				 */
				if (input.backendName && input.name in formValue) {
					if (input.type === 'checkbox') {
						params[input.backendName] = Number(formValue[input.name]);
					}
					else if (input.type === 'date') {
						params[input.backendName] = String(moment(formValue[input.name]).format('YYYY-MM-DD'));
					}
					else {
						params[input.backendName] = formValue[input.name];
					}
				}

				/**
				 * Defining: Insurance monthly payments
				 */
				else if (
					input.name.includes('installmentAmount') &&
					input.name in formValue
				) {
					const payment = {
						amount: formValue[input.name],
						date: ''
					};
					insuranceMonthlyPayments.push(payment);
				}
				else if (
					input.name.includes('installmentDate') &&
					input.name in formValue
				) {
					insuranceMonthlyPayments[insuranceMonthlyPayments.length - 1].date = moment(formValue[input.name]).format('YYYY-MM-DD');
				}

			});
		});
		// console.log(`insuranceMonthlyPayments --> `, insuranceMonthlyPayments);
		params.insurance_monthly_payments = insuranceMonthlyPayments;

		/**
		 * Defining: Tiers
		 */
		tiers.forEach( tier => {
			const backendTier: {
				start ?: any,
				end   ?: any,
				value  : number,
				type   : string,
			} = {
				value : tier.rangeValue,
				type  : tier.timeRange,
			};
			if (tier.datesRangeStart !== undefined ) backendTier.start = moment(tier.datesRangeStart ).format('YYYY-MM-DD');
			if (tier.datesRangeEnd   !== undefined ) backendTier.end   = moment(tier.datesRangeEnd   ).format('YYYY-MM-DD');
			if (tier.hoursRangeStart !== undefined ) backendTier.start = tier.hoursRangeStart ;
			if (tier.hoursRangeEnd   !== undefined ) backendTier.end   = tier.hoursRangeEnd   ;
			params.tiers.push(backendTier);
		});

		/**
		 * Defining: Statements
		 */
		statements.forEach( statement => {
			const backendStatement = {
				start : moment(statement.start ).format('YYYY-MM-DD'),
				end   : moment(statement.end   ).format('YYYY-MM-DD'),
			};
			params.statements.push(backendStatement);
		});

		// console.log(`equipments/${equipmentId}/leasebacks params --> `, params);
		// console.groupEnd();

		return this._http.post(this.apiUrl + `equipments/${equipmentId}/leasebacks`, params)
			.pipe(map((data: any) => {
				// console.log(`equipments/${equipmentId}/leasebacks data --> `, data);
				return {
					leaseback: new Leaseback().parse(data.data || {})
				};
			}));
	}

	showStatement(equipmentId: number, start: Date, end: Date) {
		const params = [
			`start=${ moment(start) .format('YYYY-MM-DD') }`,
			`end=${   moment(end)   .format('YYYY-MM-DD') }`,
		].join('&');
		return this._http.get(this.apiUrl + `equipments/${equipmentId}/leasebacks/export?` + params).pipe(
			map((data: any) => {
				// console.log(`showStatement data --> `, data);
				return {
					path: String(data?.data?.path || '')
				};
			})
		);
	}


	// -------------------------------------------------- ANCHOR: Statements

	/**
	 * Returns a list of parsed statements of a selected equipment
	 */
	getStatements(equipmentId: number) {
		return this._http.get(this.apiUrl + `equipment/statements/${equipmentId}`).pipe(
			map(( data: any )  => {
				return {
					statements: (data?.statements as any[] || []).map((item) => new Statement().parse(item)),
					total: Number( data?.total || 0 ),
				};
			}),
		);
	}

	/**
	 * Returns the parsed information of a selected statement
	 */
	getStatement(equipmentId: number, statementId: number) {
		return this._http.get(
			this.apiUrl + `equipment/statements/${equipmentId}/${statementId}`
		).pipe(
			map(( data: any )  => {
				return {
					url: data?.data?.path ? (this.apiUrl + data.data.path) : ''
				};
			}),
		);
	}


	//ASSETS//

	//General Assets Feature Integration
	getAssets(
		page: number,
		perPage: number,
		search ?: string,
		start  ?: string,
		end    ?: string,
		eventId?: number,
		all = false,
		location?: number
	) {
		let params = [
			`page=${ page }`,
			`perPage=${ perPage }`,
		].join('&');
		params = search  ? params + '&' + 'search='   + search  : params;
		params = start   ? params + '&' + 'start='    + start   : params;
		params = end     ? params + '&' + 'end='      + end     : params;
		params = eventId ? params + '&' + 'event_id=' + eventId : params;
		params = all     ? params + '&' + 'all=1'               : params;

		if(location !== undefined){
			params = params + '&location='   + location;
		}

		// console.log( `getAssets params --> `, params );
		return this._http.get(this.apiUrl + `getasset`).pipe(
			map(( data: any )  => {
				// console.log( `getAssets data --> `, data );
				return {
					assets: (data.data as any[] || []).map((item) => new Assets().parse(item))
				};
			}),
		);
	}

	//General Assets Feature Integration
	saveNewAsset(asset: Assets) {
		return this._http.post(this.apiUrl + `equipments/asset`, this.defineAssetParams(asset)).pipe(
			map(( data: any )  => {
			  console.log( `saveNewAsset data --> `, data );
				return {
					asset: new Assets().parse(data.data || {})
				};
			}),
		);
	}

	updateAsset(asset: Assets) {
		return this._http.put(this.apiUrl + `equipments/asset/${asset.id}`, this.defineAssetParams(asset)).pipe(
			map(( data: any )  => {
				 console.log( `updateAsset data --> `, data );
				return {
					asset: new Assets().parse(data.data || {})
				};
			}),
		);
	}

	disableAsset(asset: Assets) {
		const params = {
			is_active : Number(!asset.isActive),
			note : String()
		};
		return this._http.put(this.apiUrl + `equipments/asset/${asset.id}`, params)
			.pipe(map((data: any) => {
				const updatedAsset = new Assets().parse(data.data || {});
				return {
					updatedAsset
				};
			}));
	}

	/**
    @param asset saving asset object
    @returns an object of parameters used to create/edit an Asset
  */
	defineAssetParams(asset: Assets): any {
		let params: any = {};

		params = {
			resource_type: asset.resourceType,
			manufacturer: asset.manufacturer,
			model: asset.model,
			serial_number: asset.serialNumber,
			asset_number: asset.assetNumber,
			location: asset.location,
			assigned_to: asset.assignedTo,
			description: asset.description,
			note: asset.note
		};

		return params;
	}

}
