import moment from 'moment';
import { IBackendRequirement, IRequirement, Requirement } from '../requirement.model';
import { IBackendUser, IUser, User } from '../user.model';
import { IBackendKingStage, IKingStage, KingStage } from './king-stage.model';
import { IBackendStage, IStage, Stage } from './stage.model';
import { CoursePriceDetailsDto } from '../../dtos/course/course-price-details.dto';
import { CoursePriceDetails } from './course-price-details';

/** Course short model. */
export type CourseShort = {
	/** ID. */
	readonly id: number;

	/** Course name. */
	// `text` from CourseShort and 'title' from ICourse are the same thing
	// but in the old code, the 'title` is replaced by `text` in some place.
	readonly text: string;
};

/**
 * There are some responses of courses that response with the title instead of the text property.
 * They also include the is king property.
 */
export type CourseShortExtended = {
	/** Id. */
	readonly id: number;

	/** Is king. */
	readonly is_king: number | null;

	/** Course title. */
	readonly title: string;
};

export interface IBackendCourse {
	/** Details about the course price */
	pivot?: CoursePriceDetailsDto;

	created_at?: Date;
	updated_at?: Date;
	start_date?: Date;
	end_date?: Date;
	check_ride_date?: Date;
	status_updated_at?: Date;
	graduation_updated_at?: Date;
	id?: number;
	is_king?: number;

	/**
	 * Whether it is a primary course or not.
	 * It's either 1 or 0, representing a boolean value.
	 */
	is_primary?: number;

	faa_course_type?: number;
	status?: number;
	graduation_status?: number;
	abbreviation?: string;
	description?: string;
	title?: string;
	type?: string;
	status_updated_by?: string;
	graduation_updated_by?: string;
	stages?: IBackendStage[];
	phases?: IBackendStage[];
	requirements?: IBackendRequirement[];
	instructor?: IBackendUser;
	king_stage?: IBackendKingStage;

	license_type_id?: number;

	/**
	 * * snake_case are minimum the user needs to aprove the course
	 * * camelCase are the one the user currently has
	 */

	total_flight_time?: number;
	totalFlightTime?: number;
	flight_training_time?: number;
	flightTrainingTime?: number;
	trainingTime?: number;
	solo_time?: number;
	soloTime?: number;
	flight_cross_time?: number;
	flightCrossTime?: number;
	crossTime?: number;
	flight_night_time?: number;
	flightNightTime?: number;
	nightTime?: number;
	flight_training_instrument_time?: number;
	flightTrainingInstrumentTime?: number;
	instrumentTime?: number;
	solo_tower_landings?: number;
	soloTowerLandings?: number;
	towerLandings?: number;
	night_landings?: number;
	nightLandings?: number;
	ground_training_time?: number;
	groundTrainingTime?: number;
	groundTime?: number;
	training_device_time?: number;
	trainingDeviceTime?: number;
	max_ifr_sim?: number;
	maxIfrSim?: number;
	records_signed_by_customer?: number;
	recordsSignedByCustomer?: number;

	/** Aircraft group. */
	aircraftGroup?: number;

	/**
	 * Selected courses.
	 * TODO: This should be typed better.
	 */
	selectedCourses?: readonly unknown[];
}

export interface ICourse {
	/** Details about the course price */
	priceDetails?: CoursePriceDetails;

	createdAt?: Date;
	updatedAt?: Date;
	startDate?: Date;
	endDate?: Date;
	checkRideDate?: Date;
	statusUpdatedAt?: Date;
	graduationUpdatedAt?: Date;
	id?: number;
	isKing?: number;

	/** Whether it is a primary course or not. */
	isPrimary?: boolean;

	faaCourseType?: number;
	status?: number;
	graduationStatus?: number;
	abbreviation?: string;
	description?: string;
	title?: string;
	type?: string;
	statusUpdatedBy?: string;
	graduationUpdatedBy?: string;
	stages?: IStage[];
	phases?: IStage[];
	requirements?: IRequirement[];
	instructor: IUser;
	kingStage: IKingStage;
	licenseTypeId?: number;
	totalFlightTimeNeeded?: number;
	totalFlightTime?: number;
	flightTrainingTimeNeeded?: number;
	flightTrainingTime?: number;
	soloTimeNeeded?: number;
	soloTime?: number;
	flightCrossTimeNeeded?: number;
	flightCrossTime?: number;
	flightNightTimeNeeded?: number;
	flightNightTime?: number;
	flightTrainingInstrumentTimeNeeded?: number;
	flightTrainingInstrumentTime?: number;
	soloTowerLandingsNeeded?: number;
	soloTowerLandings?: number;
	nightLandingsNeeded?: number;
	nightLandings?: number;
	groundTrainingTimeNeeded?: number;
	groundTrainingTime?: number;
	recordsSignedByCustomerNeeded?: number;
	recordsSignedByCustomer?: number;

	/** Aircraft group. */
	aircraftGroup?: number;

	/**
	 * Selected courses.
	 * TODO: This should be typed better.
	 */
	selectedCourses?: readonly unknown[];
}

export class Course implements ICourse {
	static readonly clean = Object.freeze(new Course());
	createdAt?: Date;
	updatedAt?: Date;
	startDate?: Date;
	endDate?: Date;
	checkRideDate?: Date;
	statusUpdatedAt?: Date;
	graduationUpdatedAt?: Date;
	id = 0;
	isKing = 0;

	/** Whether it is a primary course or not. */
	isPrimary = false;
	faaCourseType = 0;
	status = 0;
	graduationStatus = 0;
	abbreviation = '';
	description = '';
	title = '';
	type = '';
	statusUpdatedBy = '';
	graduationUpdatedBy = '';
	stages: Stage[] = [];
	phases: Stage[] = [];
	requirements: Requirement[] = [];
	instructor: User;
	kingStage: KingStage;
	totalFlightTimeNeeded = 0;
	totalFlightTime = 0;
	flightTrainingTimeNeeded = 0;
	flightTrainingTime = 0;
	soloTimeNeeded = 0;
	soloTime = 0;
	flightCrossTimeNeeded = 0;
	flightCrossTime = 0;
	flightNightTimeNeeded = 0;
	flightNightTime = 0;
	flightTrainingInstrumentTimeNeeded = 0;
	maxInstrumentTimeSim = 0;
	flightTrainingInstrumentTime = 0;
	soloTowerLandingsNeeded = 0;
	soloTowerLandings = 0;
	nightLandingsNeeded = 0;
	nightLandings = 0;
	groundTrainingTimeNeeded = 0;
	groundTrainingTime = 0;
	trainingDeviceTimeNeeded = 0;
	trainingDeviceTime = 0;
	maxIfrSim = 0;
	maxIfrSimNeeded = 0;
	trainingDeviceIfrTime = 0;
	recordsSignedByCustomerNeeded = 0;
	recordsSignedByCustomer = 0;
	licenseTypeId = 0;

	/** Aircraft group. */
	aircraftGroup = 0;

	/** Selected courses. */
	selectedCourses: readonly unknown[] = [];

	parse(obj: IBackendCourse) {
		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : Course.clean.createdAt;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at).subtract(offset, 'hours').toDate() : Course.clean.updatedAt;
		this.startDate = obj.start_date ? moment(obj.start_date).subtract(offset, 'hours').toDate() : Course.clean.startDate;
		this.endDate = obj.end_date ? moment(obj.end_date).subtract(offset, 'hours').toDate() : Course.clean.endDate;
		this.checkRideDate = obj.check_ride_date
			? moment(obj.check_ride_date).subtract(offset, 'hours').toDate()
			: Course.clean.checkRideDate;
		this.statusUpdatedAt = obj.status_updated_at
			? moment(obj.status_updated_at).subtract(offset, 'hours').toDate()
			: Course.clean.statusUpdatedAt;
		this.graduationUpdatedAt = obj.graduation_updated_at
			? moment(obj.graduation_updated_at).subtract(offset, 'hours').toDate()
			: Course.clean.graduationUpdatedAt;

		this.id = Number(obj.id || Course.clean.id);
		this.isKing = Number(obj.is_king === 1 ? obj.is_king : Course.clean.isKing);
		this.isPrimary = Boolean(obj.is_primary);
		this.faaCourseType = Number(obj.faa_course_type || Course.clean.faaCourseType);
		this.status = Number(obj.status || Course.clean.status);
		this.graduationStatus = Number(obj.graduation_status || Course.clean.graduationStatus);
		this.abbreviation = String(obj.abbreviation || Course.clean.abbreviation);
		this.description = String(obj.description || Course.clean.description);
		this.title = String(obj.title || Course.clean.title);
		this.type = String(obj.type || Course.clean.type);
		this.statusUpdatedBy = String(obj.status_updated_by || Course.clean.statusUpdatedBy);
		this.graduationUpdatedBy = String(obj.graduation_updated_by || Course.clean.graduationUpdatedBy);
		this.stages = (obj.stages || []).map((item) => new Stage().parse(item));
		this.phases = (obj.phases || []).map((item) => new Stage().parse(item));
		this.requirements = (obj.requirements || []).map((item) => new Requirement().parse(item));
		this.instructor = obj.instructor ? new User().parse(obj.instructor) : new User();
		this.kingStage = obj.king_stage ? new KingStage().parse(obj.king_stage) : new KingStage();
		this.licenseTypeId = Number(obj.license_type_id || Course.clean.licenseTypeId);

		this.totalFlightTimeNeeded = Number(Number(obj.total_flight_time || Course.clean.totalFlightTimeNeeded).toFixed(2));
		this.totalFlightTime = Number(Number(obj.totalFlightTime || Course.clean.totalFlightTime).toFixed(2));
		this.flightTrainingTimeNeeded = Number(
			Number(obj.flight_training_time || Course.clean.flightTrainingTimeNeeded).toFixed(2),
		);
		this.flightTrainingTime = Number(
			Number(obj.flightTrainingTime || obj.trainingTime || Course.clean.flightTrainingTime).toFixed(2),
		);
		this.soloTimeNeeded = Number(Number(obj.solo_time || Course.clean.soloTimeNeeded).toFixed(2));
		this.soloTime = Number(Number(obj.soloTime || Course.clean.soloTime).toFixed(2));
		this.flightCrossTimeNeeded = Number(Number(obj.flight_cross_time || Course.clean.flightCrossTimeNeeded).toFixed(2));
		this.flightCrossTime = Number(
			Number(obj.flightCrossTime || obj.crossTime || Course.clean.flightCrossTime).toFixed(2),
		);
		this.flightNightTimeNeeded = Number(Number(obj.flight_night_time || Course.clean.flightNightTimeNeeded).toFixed(2));
		this.flightNightTime = Number(
			Number(obj.flightNightTime || obj.nightTime || Course.clean.flightNightTime).toFixed(2),
		);
		this.flightTrainingInstrumentTimeNeeded = Number(
			Number(obj.flight_training_instrument_time || Course.clean.flightTrainingInstrumentTimeNeeded).toFixed(2),
		);
		this.flightTrainingInstrumentTime = Number(
			Number(obj.flightTrainingInstrumentTime || obj.instrumentTime || Course.clean.flightTrainingInstrumentTime).toFixed(
				2,
			),
		);
		this.soloTowerLandingsNeeded = Number(
			Number(obj.solo_tower_landings || Course.clean.soloTowerLandingsNeeded).toFixed(2),
		);
		this.soloTowerLandings = Number(
			Number(obj.soloTowerLandings || obj.towerLandings || Course.clean.soloTowerLandings).toFixed(2),
		);
		this.nightLandingsNeeded = Number(Number(obj.night_landings || Course.clean.nightLandingsNeeded).toFixed(2));
		this.nightLandings = Number(Number(obj.nightLandings || Course.clean.nightLandings).toFixed(2));
		this.groundTrainingTimeNeeded = Number(
			Number(obj.ground_training_time || Course.clean.groundTrainingTimeNeeded).toFixed(2),
		);
		this.groundTrainingTime = Number(
			Number(obj.groundTrainingTime || obj.groundTime || Course.clean.groundTrainingTime).toFixed(2),
		);
		this.trainingDeviceTimeNeeded = Number(
			Number(obj.training_device_time || Course.clean.trainingDeviceTimeNeeded).toFixed(2),
		);
		this.trainingDeviceTime = Number(Number(obj.trainingDeviceTime || Course.clean.trainingDeviceTime).toFixed(2));
		this.recordsSignedByCustomerNeeded = Number(
			Number(obj.records_signed_by_customer || Course.clean.recordsSignedByCustomerNeeded).toFixed(2),
		);
		this.recordsSignedByCustomer = Number(
			Number(obj.recordsSignedByCustomer || Course.clean.recordsSignedByCustomer).toFixed(2),
		);

		this.maxIfrSimNeeded = Number(Number(obj.max_ifr_sim || Course.clean.maxIfrSimNeeded).toFixed(2));
		this.maxIfrSim = Number(Number(obj.maxIfrSim || Course.clean.maxIfrSim).toFixed(2));

		this.aircraftGroup = Number(obj.aircraftGroup || Course.clean.aircraftGroup);
		this.selectedCourses = obj.selectedCourses || Course.clean.selectedCourses;

		/**                                                                                             ^^^^^ noticed backend used these ^^^^^                                                                */
		return this;
	}

	set(obj: ICourse) {
		Object.entries(obj).forEach(([key]) => (this[key] = obj[key]));

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Course.clean[key];
		});

		return this;
	}

	clone() {
		return new Course().set(this);
	}
}
