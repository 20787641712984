import moment from "moment";
import { IBackendItem, IItem, Item } from '../item.model';
import { IBackendWorkOrder, IWorkOrder, WorkOrder } from '../work-order/work-order.model';
import { IBackendWorkOrderItemLog, IWorkOrderItemLog, WorkOrderItemLog } from '../work-order/work-order-item-log.model';
import { WorkOrderPoNote, IBackendWorkOrderPoNote, IWorkOrderPoNote } from './work-order-po-note.model';

export interface IBackendWorkOrderItem{
	id        : number;
	order_id  : number;
	item_id?   : number;
	requested_by : number;
	requested_by_name? : string; //phantom variable
	quantity   : number;
	status?   : number;
	part_number? : string;
	description? : string;
	po_number? : string;
	po_status? : number;
	aog? : number;
	created_at? : Date;
	updated_at? : Date;
	item?: IBackendItem;
	work_order?: IBackendWorkOrder;
	logs?: IBackendWorkOrderItemLog[];
	po_notes?: IBackendWorkOrderPoNote[];
}

export interface IWorkOrderItem{
	id        : number;
	orderId  : number;
	itemId?   : number;
	requestedBy : number;
	requestedByName? : string; //phantom variable
	quantity   : number;
	status?   : number;
	partNumber? : string;
	description? : string;
	poNumber? : string;
	poStatus? : number;
	aog? : number;
	createdAt?: Date;
	updatedAt?: Date;
	item?: IItem;
	requestItem?: boolean;
	workOrder?: IWorkOrder;
	logs?: IWorkOrderItemLog[];
	poNotes?: IWorkOrderPoNote[];
}

export class WorkOrderItem implements IWorkOrderItem {
	static readonly clean = Object.freeze(new WorkOrderItem());
	id = 0;
	orderId = 0;
	itemId = 0;
	requestedBy = 0;
	requestedByName? = ''; //phantom variable
	quantity = 0;
	status = 0;
	partNumber = '';
	description = '';
	poNumber = '';
	poStatus = 0;
	aog = 0;
	createdAt? : Date;
	updatedAt? : Date;
	item?: Item;
	requestItem = false;
	workOrder?: WorkOrder;
	logs?: WorkOrderItemLog[];
	poNotes?: WorkOrderPoNote[];

	parse(obj: IBackendWorkOrderItem) {

		const offset = Number(new Date().getTimezoneOffset() / 60);
		//this.createdAt	= 		 obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : Note.clean.createdAt;
		//this.updatedAt	= 		 obj.updated_at ? moment(obj.updated_at).subtract(offset, 'hours').toDate() : Note.clean.updatedAt;

		this.id       	= Number(obj.id           || WorkOrderItem.clean.id      );
		this.orderId  	= Number(obj.order_id     || WorkOrderItem.clean.orderId );
		this.itemId   	= Number('item_id' in obj ? obj.item_id : WorkOrderItem.clean.itemId);
		this.requestedBy   	= Number(obj.requested_by  || WorkOrderItem.clean.requestedBy  );
		this.requestedByName  = String('requested_by_name' in obj ? obj.requested_by_name : WorkOrderItem.clean.requestedByName);
		this.quantity  	= Number(obj.quantity     || WorkOrderItem.clean.quantity );
		this.status  	= Number('status' in obj ? obj.status : WorkOrderItem.clean.status);
		this.partNumber   	= String('part_number' in obj ? obj.part_number : WorkOrderItem.clean.partNumber);
		this.description   	= String('description' in obj ? obj.description : WorkOrderItem.clean.description);
		this.poNumber   	= String('po_number' in obj ? obj.po_number == null ? '' : obj.po_number : WorkOrderItem.clean.poNumber);
		this.poStatus  	= Number('po_status' in obj ? obj.po_status : WorkOrderItem.clean.poStatus);
		this.aog  	= Number('aog' in obj ? obj.aog : WorkOrderItem.clean.aog);
		this.createdAt	= obj.created_at ? moment(obj.created_at).toDate() : WorkOrderItem.clean.createdAt;
		this.updatedAt	= obj.updated_at ? moment(obj.updated_at).toDate() : WorkOrderItem.clean.updatedAt;
		if (obj.item) { this.item = new Item().parse(obj.item); }
		if (obj.work_order) { this.workOrder = new WorkOrder().parse(obj.work_order); }
		if (obj.logs) { this.logs = (obj.logs as any[] || []).map(log => new WorkOrderItemLog().parse(log)); }
		if (obj.po_notes) { this.poNotes = (obj.po_notes as any[] || []).map(note => new WorkOrderPoNote().parse(note)); }
		return this;
	}

	set(obj: IWorkOrderItem) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = WorkOrderItem.clean[key];
		});

		return this;
	}

	clone() {
		return new WorkOrderItem().set(this);
	}
}
