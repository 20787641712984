import moment from 'moment';
import { IBackendUserCourse, IUserCourse, UserCourse } from './course/userCourse.model';
import { Group, IBackendGroup, IGroups } from './group.model';
import { IBackendItem, IItem, Item } from './item.model';
import { IBackendPermissions, IPermissions, Permissions } from './permissions.model';
import { IBackendRequirement, IRequirement, Requirement } from './requirement.model';
import { IBackendUserCheckouts, IUserCheckouts, UserCheckouts } from './user-checkout.model';
import { Course, IBackendCourse, ICourse } from './course/course.model';

export interface IBackendUser {
	id?: number;
	user_id?: number;
	created_at?: Date;
	updated_at?: Date;
	birthdate?: any;
	is_active?: number;
	groups?: IBackendGroup[];
	courses?: IBackendUserCourse[];
	course?: IBackendUserCourse;
	quoted_courses?: IBackendCourse[];
	requirements?: IBackendRequirement[];
	checkouts?: IBackendUserCheckouts;
	permissions?: IBackendPermissions;
	credentials?: IBackendRequirement[];

	// personal info
	username?: string;
	avatar_path?: string;
	client_type_id?: number;
	client_subtype_id?: number[];
	email?: string;
	emergency_contact_name?: string;
	emergency_contact_phone?: string;
	first_name?: string;
	full_name?: string;
	last_name?: string;
	nick_name?: string;
	phone?: string;
	secondary_phone?: string;
	user_residency_id?: number;
	role_id?: number;
	role?: string;
	ftn_number?: string;
	certificate_number?: string;
	pin?: string;
	rating?: string[];
	cfi_number?: string;
	hourly_rate?: number;
	license_number?: string;
	client_license_number?: string;
	checkpoint_authorization?: number;
	syllabus_lesson_id?: number;
	lesson_title?: string;
	checkride_status?: number;
	credentials_updated?: number;

	// Quickbooks
	qb_name?: string;
	save_quickbooks?: number;
	qb_customer_id?: number;
	qb_customer_sync_token?: string;

	// Contact info
	address?: string;
	country?: string;
	city?: string;
	state?: string;
	zip_code?: string;

	address2?: string;
	country2?: string;
	city2?: string;
	state2?: string;
	zip_code2?: string;
	stop_sms_on?: string | null;
	sms_enabled?: boolean;

	// course-info
	notes?: string;
	primary_instructor_id?: number;
	primary_instructor_name?: string;
	primary_instructor_email?: string;
	secondary_instructor_id?: number;
	syllabus_course_id?: number;
	license_type_id?: number[];
	maintenance_license_type_id?: number[];

	budget?: number;
	user_balance_used?: number;
	balance?: number;
	user_budget_used?: number;
	customer_course_id?: number;
	customer_id?: number;
	student_id?: string;
	reservations_left?: number;
	upcoming_stage?: string;

	// invoice
	non_payable_with_budget?: number;
	payment_method?: string[];
	payment_cash?: number;
	payment_card?: number;
	payment_card_type?: string;
	payment_check?: number;
	payment_check_number?: string;
	discount?: number;
	fuel_burn_per_hour?: number;
	payment_total?: number;
	subtotal?: number;
	subtotal_minus_non?: number;
	subtotal_minus_discount?: number;
	total?: number;
	total_final?: number;
	// equipment_cost          ?: number;
	// equipment_fuel_price    ?: number;
	// equipment_hourly_rate   ?: number;
	// fuel_cost               ?: number;
	// instructor_hourly_rate  ?: number;
	// instructor_hours        ?: number;
	// instructor_cost         ?: number;
	hobbs1_in?: number;
	hobbs1_out?: number;
	hobbs?: number;
	invalid_item_index?: number;
	financing?: number;

	// Fly
	allow_to_fly?: number;
	cleared_for_flight?: number;
	can_client_fly?: number;
	is_blocked?: number;
	balance_limit?: number;
	vip?: number;
	vip_notes?: string | null;
	location?: number;

	items?: IBackendItem[];
	timeShift?: number[];
}

export interface IUser {
	id?: number;
	createdAt?: Date;
	updatedAt?: Date;
	birthdate?: any;
	isActive?: boolean;
	groups?: IGroups[];
	courses?: IUserCourse[];
	quoted_courses?: ICourse[];
	course?: IUserCourse;
	requirements?: IRequirement[];
	credentials?: IRequirement[];
	checkouts?: IUserCheckouts;
	permissions?: IPermissions;

	// personal info
	username?: string;
	avatarPath?: string;
	clientTypeId?: number;
	clientSubTypeId?: number[];
	email?: string;
	emergencyContactName?: string;
	emergencyContactPhone?: string;
	firstName?: string;
	fullName?: string;
	lastName?: string;
	nickName?: string;
	phone?: string;
	secondaryPhone?: string;
	userResidencyId?: number;
	role_id?: number;
	roleId?: number;
	role?: string;
	ftnNumber?: string;
	certificateNumber?: string;
	pin?: string;
	rating?: string[];
	cfiNumber?: string;
	hourlyRate?: number;
	licenseNumber?: string;
	clientLicenseNumber?: string;
	checkpointAuthorization?: boolean;
	lessonId?: number;
	lessonTitle?: string;
	checkrideStatus?: number;
	credentialsUpdated?: boolean;

	// Quickbooks
	qbName?: string;
	saveQuickbooks?: string;
	qbCustomerId?: number;
	qbCustomerSyncToken?: string;

	// Contact info
	address?: string;
	country?: string;
	city?: string;
	state?: string;
	zipCode?: string;

	address2?: string;
	country2?: string;
	city2?: string;
	state2?: string;
	zipCode2?: string;
	stop_sms_on?: Date | null;
	sms_enabled?: boolean;

	// course-info
	notes?: string;
	primaryInstructorId?: number;
	primaryInstructorName?: string;
	primaryInstructorEmail?: string;
	secondaryInstructorId?: number;
	syllabusCourseId?: number;
	licenseTypeId?: number[];
	maintenanceLicenseTypeId?: number[];

	budget?: number;
	userBalanceUsed?: number;
	balance?: number;
	userBudgetUsed?: number;
	customerCourseId?: number;
	customerId?: number;
	studentId?: string;
	reservationsLeft?: number;
	upcomingStage?: string;
	// invoice
	nonPayableWithBudget?: number;
	paymentMethod?: string[];
	paymentCash?: number;
	paymentCard?: number;
	paymentCardType?: string;
	paymentCheck?: number;
	paymentCheckNumber?: string;
	discount?: number;
	fuelBurnPerHour?: number;
	paymentTotal?: number;
	subtotal?: number;
	subtotalMinusNon?: number;
	subtotalMinusDiscount?: number;
	total?: number;
	totalFinal?: number;
	// equipmentCost        ?: number;
	// equipmentFuelPrice   ?: number;
	// equipmentHourlyRate  ?: number;
	// fuelCost             ?: number;
	// instructorHourlyRate ?: number;
	// instructorHours      ?: number;
	// instructorCost       ?: number;
	hobbs1In?: number;
	hobbs1Out?: number;
	hobbs?: number;
	isAnItemInvalid?: number;
	financing?: number;

	// Fly
	allowToFly?: string;
	clearedForFlight?: string;
	canClientFly?: boolean;
	isBlocked?: number;
	balanceLimit?: string;
	vip?: string;
	vip_notes?: string | null;
	location?: number;

	items?: IItem[];

	timeShift?: number[];
}

export class User implements IUser {
	static readonly clean = Object.freeze(new User());
	id = 0;
	createdAt?: Date;
	updatedAt?: Date;
	birthdate?: any;
	isActive = true;
	groups: Group[] = [];
	courses: UserCourse[] = [];
	course: UserCourse;
	quoted_courses: Course[];
	requirements: Requirement[] = [];
	checkouts: UserCheckouts;
	permissions: Permissions;

	credentials?: Requirement[] = [];

	// personal info
	username = '';
	avatarPath = '';
	clientTypeId = 0;
	clientSubTypeId = [];
	email = '';
	emergencyContactName = '';
	emergencyContactPhone = '';
	firstName = '';
	fullName = '';
	lastName = '';
	nickName = '';
	phone = '';
	secondaryPhone = '';
	userResidencyId = 0;
	role_id = 0;
	/**
	 * ### Updated list:
	 * * 5  Client
	 * * 1  Owner
	 * * 7  Admin
	 * * 8  Student Ambassador
	 * * 9  Auditor
	 * * 10 Accounting
	 * * 2  Instructor
	 * * 11 Check Instructor
	 * * 12 Chief Instructor
	 * * 3  Maintenance
	 * * 4  Dispatcher
	 * * 6  Support Staff
	 * * 13 DPE
	 */
	roleId = 0;
	role = '';
	roleColor = '#cccccc';
	roleName = '';
	ftnNumber = '';
	certificateNumber = '';
	pin = '';
	rating = [];
	cfiNumber = '';
	hourlyRate = 0;
	licenseNumber = '';
	clientLicenseNumber = '';
	checkpointAuthorization = false;
	lessonId = 0;
	lessonTitle = '';
	checkrideStatus = 0;
	credentialsUpdated = true;

	// Quickbooks
	qbName = '';
	saveQuickbooks = '0';
	qbCustomerId = 0;
	qbCustomerSyncToken = '';

	// Contact info
	address = '';
	country = '';
	city = '';
	state = '';
	zipCode = '';

	address2 = '';
	country2 = '';
	city2 = '';
	state2 = '';
	zipCode2 = '';
	stop_sms_on = null;
	sms_enabled = false;
	// course-info
	notes = '';
	primaryInstructorId = 0;
	primaryInstructorName = '';
	primaryInstructorEmail = '';
	secondaryInstructorId = 0;
	syllabusCourseId = 0;
	licenseTypeId = [];
	maintenanceLicenseTypeId = [];

	budget = 0;
	userBalanceUsed = 0;
	balance = 0;
	userBudgetUsed = 0;
	customerCourseId = 0;
	customerId = 0;
	studentId = '';
	reservationsLeft = 0;
	upcomingStage = '';

	// invoice
	/** Total amount that cannot be paid with budget */
	nonPayableWithBudget = 0;
	paymentMethod: string[] = [];
	paymentCash = 0;
	paymentCard = 0;
	paymentCardType = '';
	paymentCheck = 0;
	paymentCheckNumber = '';
	discount = 0;
	fuelBurnPerHour = 0;
	paymentTotal = 0;
	subtotal = 0;
	/** subtotal - sum of items that are not payable with budget */
	subtotalMinusNon = 0;
	subtotalMinusDiscount = 0;
	/** (total - ((budget - nonPayableWithBudget) + balance)) */
	total = 0;
	totalFinal = 0;
	// equipmentCost         = 0;
	// equipmentFuelPrice    = 0;
	// equipmentHourlyRate   = 0;
	// fuelCost              = 0;
	// instructorHourlyRate  = 0;
	// instructorHours       = 0;
	// instructorCost        = 0;
	financing = 0;

	prePostFlightTotal = 0;
	hobbs1In = 0;
	hobbs1Out = 0;
	hobbs = 0;
	isAnItemInvalid = 0;

	// Fly
	allowToFly = 'yes';
	clearedForFlight = 'yes';
	canClientFly = true;
	isBlocked = 0;
	balanceLimit = '1';
	vip = '0';
	vip_notes = '';
	location = 0;

	items: IItem[] = [];
	timeShift = [];

	parse(obj: IBackendUser) {
		// console.log( `obj --> `, obj );
		this.id = Number(obj.user_id || obj.id || User.clean.id);

		// this.createdAt    = obj.created_at ? moment.utc(obj.created_at).toDate() : User.clean.createdAt;
		// this.updatedAt    = obj.updated_at ? moment.utc(obj.updated_at).toDate() : User.clean.updatedAt;

		/**
		 * These moment.utc functions are behaving strangely, were an hour off :c
		 * so we're using offset to convert to local times
		 */
		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.createdAt = obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : User.clean.createdAt;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at).subtract(offset, 'hours').toDate() : User.clean.updatedAt;

		this.birthdate = String(obj.birthdate || User.clean.birthdate);
		this.isActive = Boolean('is_active' in obj ? obj.is_active : User.clean.isActive);
		this.groups = (obj.groups || []).map((item) => new Group().parse(item));
		this.courses = (obj.courses || []).map((item) => new UserCourse().parse(item));
		this.quoted_courses = (obj.quoted_courses || []).map((item) => new Course().parse(item));
		this.requirements = (obj.credentials || []).map((item) => new Requirement().parse(item));
		if (obj.course) {
			this.course = new UserCourse().parse(obj.course);
		}
		if (obj.checkouts) {
			this.checkouts = new UserCheckouts().parse(obj.checkouts);
		}
		if (obj.permissions) {
			this.permissions = new Permissions().parse(obj.permissions);
		}

		this.credentials = (obj.credentials || []).map((item) => new Requirement().parse(item));

		// personal info
		this.username = String(obj.username || User.clean.username);
		this.avatarPath = String(obj.avatar_path || User.clean.avatarPath);
		this.clientTypeId = Number(obj.client_type_id || User.clean.clientTypeId);
		this.clientSubTypeId = obj.client_subtype_id || User.clean.clientSubTypeId;
		this.email = String(obj.email || User.clean.email);
		this.emergencyContactName = String(obj.emergency_contact_name || User.clean.emergencyContactName);
		this.emergencyContactPhone = String(obj.emergency_contact_phone || User.clean.emergencyContactPhone);
		this.firstName = this.capitalizeFirstLetter(String(obj.first_name || User.clean.firstName));
		this.lastName = this.capitalizeFirstLetter(String(obj.last_name || User.clean.lastName));
		this.fullName = this.capitalizeFullName(String(obj.full_name ? obj.full_name : obj.first_name + ' ' + obj.last_name));
		this.phone = String(obj.phone || User.clean.phone);
		this.secondaryPhone = String(obj.secondary_phone || User.clean.secondaryPhone);
		this.userResidencyId = Number(obj.user_residency_id || User.clean.userResidencyId);
		this.role_id = Number(obj.role_id || User.clean.role_id);
		this.roleId = Number(obj.role_id || User.clean.roleId);
		this.role = String(obj.role || User.clean.role);
		this.ftnNumber = String(obj.ftn_number || User.clean.ftnNumber);
		this.certificateNumber = String(obj.certificate_number || User.clean.certificateNumber);
		this.pin = String(obj.pin || User.clean.pin);
		this.rating = obj.rating || User.clean.rating;
		this.cfiNumber = String(obj.cfi_number || User.clean.cfiNumber);
		this.hourlyRate = Number(obj.hourly_rate || User.clean.hourlyRate);
		this.licenseNumber = String(obj.license_number || User.clean.licenseNumber);
		this.clientLicenseNumber = String(obj.client_license_number || User.clean.clientLicenseNumber);
		this.checkpointAuthorization = Boolean(obj.checkpoint_authorization || User.clean.checkpointAuthorization);
		this.lessonId = Number(obj.syllabus_lesson_id || User.clean.lessonId);
		this.lessonTitle = String(obj.lesson_title || User.clean.lessonTitle);
		this.checkrideStatus = Number(obj.checkride_status || User.clean.checkrideStatus);
		this.credentialsUpdated = Boolean(
			'credentials_updated' in obj ? obj.credentials_updated : User.clean.credentialsUpdated,
		);

		// Quickbooks
		this.qbName = String(obj.qb_name || User.clean.qbName);
		this.saveQuickbooks = String(
			'save_quickbooks' in obj ? (obj.save_quickbooks ? '1' : '0') : User.clean.saveQuickbooks,
		);
		this.qbCustomerId = Number(obj.qb_customer_id || User.clean.qbCustomerId);
		this.qbCustomerSyncToken = String(obj.qb_customer_sync_token || User.clean.qbCustomerSyncToken);

		// Contact info
		this.address = String(obj.address || User.clean.address);
		this.country = String(obj.country || User.clean.country);
		this.city = String(obj.city || User.clean.city);
		this.state = String(obj.state || User.clean.state);
		this.zipCode = String(obj.zip_code || User.clean.zipCode);

		this.address2 = String(obj.address2 || User.clean.address2);
		this.country2 = String(obj.country2 || User.clean.country2);
		this.city2 = String(obj.city2 || User.clean.city2);
		this.state2 = String(obj.state2 || User.clean.state2);
		this.zipCode2 = String(obj.zip_code2 || User.clean.zipCode2);
		this.stop_sms_on = obj.stop_sms_on ? new Date(obj.stop_sms_on) : null;
		this.sms_enabled = Boolean(obj.sms_enabled || User.clean.sms_enabled);

		// course-info
		this.notes = String(obj.notes || User.clean.notes);
		this.primaryInstructorId = Number(obj.primary_instructor_id || User.clean.primaryInstructorId);
		this.primaryInstructorName = String(obj.primary_instructor_name || User.clean.primaryInstructorName);
		this.primaryInstructorEmail = String(obj.primary_instructor_email || User.clean.primaryInstructorEmail);
		this.secondaryInstructorId = Number(obj.secondary_instructor_id || User.clean.secondaryInstructorId);
		this.syllabusCourseId = Number(obj.syllabus_course_id || User.clean.syllabusCourseId);
		this.maintenanceLicenseTypeId = obj.maintenance_license_type_id || User.clean.maintenanceLicenseTypeId;
		this.licenseTypeId = obj.license_type_id || User.clean.licenseTypeId;

		this.budget = Number(obj.budget || User.clean.budget);
		this.userBalanceUsed = Number(obj.user_balance_used || User.clean.userBalanceUsed);
		this.balance = Number(obj.balance || User.clean.balance);
		this.userBudgetUsed = Number(obj.user_budget_used || User.clean.userBudgetUsed);
		this.customerCourseId = Number(obj.customer_course_id || User.clean.customerCourseId);
		this.customerId = Number(obj.customer_id || User.clean.customerId);
		this.studentId = String(obj.student_id || User.clean.studentId);
		this.reservationsLeft = Number(obj.reservations_left || User.clean.reservationsLeft);
		this.upcomingStage = String(obj.upcoming_stage || User.clean.upcomingStage);

		// invoice
		this.nonPayableWithBudget = Number(obj.non_payable_with_budget || User.clean.nonPayableWithBudget);
		this.paymentMethod = obj.payment_method || User.clean.paymentMethod;
		this.paymentCash = Number(obj.payment_cash || User.clean.paymentCash);
		this.paymentCard = Number(obj.payment_card || User.clean.paymentCard);
		this.paymentCardType = String(obj.payment_card_type || User.clean.paymentCardType);
		this.paymentCheck = Number(obj.payment_check || User.clean.paymentCheck);
		this.paymentCheckNumber = String(obj.payment_check_number || User.clean.paymentCheckNumber);
		this.discount = Number(obj.discount || User.clean.discount);
		this.fuelBurnPerHour = Number(obj.fuel_burn_per_hour || User.clean.fuelBurnPerHour);
		this.paymentTotal = Number(obj.payment_total || User.clean.paymentTotal);
		this.subtotal = Number(obj.subtotal || User.clean.subtotal);
		this.subtotalMinusNon = Number(obj.subtotal_minus_non || User.clean.subtotalMinusNon);
		this.subtotalMinusDiscount = Number(obj.subtotal_minus_discount || User.clean.subtotalMinusDiscount);
		this.total = Number(obj.total || User.clean.total);
		this.totalFinal = Number(obj.total_final || User.clean.totalFinal);
		this.hobbs1In = Number(Number(obj.hobbs1_in || User.clean.hobbs1In).toFixed(1));
		this.hobbs1Out = Number(Number(obj.hobbs1_out || User.clean.hobbs1Out).toFixed(1));
		this.hobbs = Number(Number(this.hobbs1In - this.hobbs1Out || User.clean.hobbs).toFixed(1));
		this.isAnItemInvalid = Number(obj.invalid_item_index || User.clean.isAnItemInvalid);
		this.financing = Number(obj.financing || User.clean.financing);

		// Fly
		this.allowToFly = obj.allow_to_fly !== undefined && obj.allow_to_fly === 0 ? 'no' : 'yes';
		this.clearedForFlight = obj.cleared_for_flight !== undefined && obj.cleared_for_flight === 0 ? 'no' : 'yes';
		this.canClientFly = Boolean(obj.can_client_fly);
		this.isBlocked = Number(obj.is_blocked || User.clean.isBlocked);
		this.balanceLimit = String('balance_limit' in obj ? (obj.balance_limit ? '1' : '0') : User.clean.balanceLimit);
		this.vip = String('vip' in obj ? (obj.vip ? '1' : '0') : User.clean.vip);
		this.vip_notes = String(obj.vip_notes ?? User.clean.vip_notes);
		this.location = Number(obj.location || User.clean.location);
		this.roleColor = this.getRoleColor(this.roleId);
		this.roleName = this.getRoleName(this.roleId);

		this.nickName = String('nick_name' in obj ? obj.nick_name : User.clean.nickName);

		this.items = (obj.items || []).map((item) => new Item().parse(item));
		this.timeShift = obj.timeShift || User.clean.timeShift;
		return this;
	}

	set(obj: IUser) {
		Object.entries(obj).forEach(([key]) => (this[key] = obj[key]));

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = User.clean[key];
		});

		return this;
	}

	clone() {
		return new User().set(this);
	}

	getRoleColor(roleId: number): string {
		let color = '#545d8e';

		switch (roleId) {
			case 1: // Owner
				color = '#4c57c0';
				break;
			case 2: // Instructor
				color = '#e86831';
				break;
			case 3: // Maintenance
				color = '#21b879';
				break;
			case 4: // Dispatcher
				color = '#1dc3d0';
				break;
			case 5: // Client
				color = '#1391df';
				break;
			case 6: // Support Staff
				color = '#545d8e';
				break;
			case 7: // Admin
				color = '#7f2f9f';
				break;
			case 8: // Student Ambassador
				color = '#ffa333';
				break;
			case 9: // Auditor
				color = '#ff8533';
				break;
			case 10: // Accounting
				color = '#49a6f7';
				break;
			case 11: // Check Instructor
				color = '#8f21e3';
				break;
			case 12: // Chief Instructor
				color = '#d01dba';
				break;
			case 13: // DPE
				color = '#233f88';
				break;
			default:
				break;
		}

		return color;
	}

	getRoleName(roleId: number): string {
		let name = '';

		switch (roleId) {
			case 1:
				name = 'Owner';
				break;
			case 2:
				name = 'Instructor';
				break;
			case 3:
				name = 'Maintenance';
				break;
			case 4:
				name = 'Dispatcher';
				break;
			case 5:
				name = 'Client';
				break;
			case 6:
				name = 'Support Staff';
				break;
			case 7:
				name = 'Admin';
				break;
			case 8:
				name = 'Student Ambassador';
				break;
			case 9:
				name = 'Auditor';
				break;
			case 10:
				name = 'Accounting';
				break;
			case 11:
				name = 'Check Instructor';
				break;
			case 12:
				name = 'Chief Instructor';
				break;
			case 13:
				name = 'DPE';
				break;
			default:
				break;
		}

		return name;
	}

	capitalizeFirstLetter(str: string): string {
		if (!str) return str; // Handle empty string or null input

		return str
			.split(/([-\s])/g) // Split by space or hyphen, keeping delimiters
			.map(
				(segment) =>
					segment.match(/[-\s]/) // Check if it's a delimiter
						? segment // Keep delimiters as they are
						: segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase(), // Capitalize word
			)
			.join(''); // Join everything back together
	}

	capitalizeFullName(fullName: string): string {
		if (!fullName) return fullName; // Handle empty string or null input

		return fullName
			.split(' ') // Split by space to get each word
			.filter((word) => word.trim() !== '') // Remove extra blank spaces
			.map((word) => this.capitalizeFirstLetter(word)) // Use the updated capitalizeFirstLetter
			.join(' '); // Join the words back into a single string
	}
}
