/* eslint-disable no-useless-escape */
// angular
import { Injectable } from '@angular/core';

// packages
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import moment from 'moment';

//const moment = _moment;

// local
import { SnackbarComponent } from 'src/app/_core/shared/components/snackbar/snackbar.component';
import { LoginService } from './api/login.service';

@Injectable({
	providedIn: 'root',
})
export class SnackbarService {
	constructor(
		private _loginService: LoginService,
		private _router: Router,
		private _snackBar: MatSnackBar,
	) {}

	// SNACKBAR --------------------------------------------------------------------

	/**
	 * Opens the Snackbar Component showing the selected message
	 */
	show(message: string, action: 'close', type: 'success' | 'warning') {
		const seconds = message.length > 40 ? message.length / 10 : 4;
		this._snackBar.openFromComponent(SnackbarComponent, {
			data: { message, action, type, seconds },
			panelClass: type,
			horizontalPosition: 'left',
		});
	}

	// ERRORS ---------------------------------------------------------------------

	/**
	 * Uses to display snabackbar errors
	 * @param method Name of the method where the error ocurred to be displayed in console
	 * @param err Error object obtained
	 */
	errorHandler(method: string, err?: any) {
		try {
			console.error(method, err);
			let errorMessage = 'There was an error, please try again later.';

			if (err?.error?.message && err.error.message === 'Unauthorized') {
				errorMessage = 'Authorization error';
				localStorage.clear();
				sessionStorage.clear();
				this._router.navigate(['login']);
			} else if (err && err.error && err.error.message) {
				if (
					err.error.message === 'the password is invalid' ||
					err.error.message === 'The selected email is invalid.' ||
					err.error.message === 'invalid_username_or_email'
				) {
					errorMessage = 'The email, user name or password is invalid.';
				} else if (err.error.error === 'event_conflict') {
					errorMessage =
						err.error.message +
						' event(s) upcoming for this equipment in a diferent location that the one selected, please update/remove them before creating a time availability';
				} else if (err.error.message === 'no_dates_available') {
					errorMessage =
						err.error.lesson && err.error.lesson.title
							? `Reservations need to be assigned to lesson: "${err.error.lesson.title}"`
							: 'No date options found';
				} else if (err.error.message === 'selected_primary_instructor_cannot_teach_current_users_course') {
					errorMessage = `The selected primary instructor cannot teach the course the client is enrolled in.`;
				} else if (err.error.message === 'The selected id is invalid.') {
					errorMessage = `The selected id is invalid.`;
					if (method === 'getSingleCourse') {
						errorMessage = `The selected course is not available.`;
					}
				} else if (err.error.message === 'invalid_username') {
					errorMessage = `Invalid username. Use only numbers and letters without spaces. Underscores allowed.`;
				} else if (err.error.message === 'The username has already been taken.') {
					errorMessage = `The username has already been taken.`;
				} else if (err.error.message === 'wrong_client_type_id' && err.error.client_type) {
					errorMessage = `Selected client has wrong client type (${err.error.client_type})`;
				} else if (err.error.message === 'privileges') {
					errorMessage = 'You do not have the necessary privileges to perform this action.';
				} else if (err.error.message === 'override_limit_reached' || err.error.message === 'override_limit_reach') {
					errorMessage = 'You have reached the limit of times that you can overwrite this credential';
				} else if (err.error.message === 'Privileges') {
					errorMessage = 'You do not have the privileges to perform this action';
				} else if (err.error.message === 'time_taken') {
					errorMessage = 'The event cannot be created/updated because it overlaps with another event';
				} else if (err.error.message === 'equipment_cannot_fly') {
					errorMessage = 'Selected equipment has issues/squawks pending to resolve.';
				} else if (err.error.message === 'time_taken_by_client') {
					errorMessage =
						'The event cannot be created/updated because this <b>client</b> has another reservation at same time';
				} else if (err.error.message === 'time_taken_by_equipment') {
					errorMessage =
						'The event cannot be created/updated because the same <b>equipment</b> is in another reservation at same time';
				} else if (err.error.message === 'time_taken_by_instructor') {
					errorMessage =
						'The event cannot be created/updated because the <b>instructor</b> has another reservation at same time';
				} else if (err.error.message === 'invalid_requirement_type') {
					errorMessage = 'This credential does not apply to the selected user.';
				} else if (err.error.message === 'The file failed to upload.') {
					errorMessage = 'There was an error uploading your file.';
				} else if (err.error.message === 'time_taken_equipment') {
					errorMessage = 'The event cannot be created/updated because the equipment is busy for another event';
				} else if (err.error.message === 'cancel_event_permission') {
					errorMessage = `You don't have permission to cancel this event`;
				} else if (err.error.message === 'missing_lesson') {
					errorMessage = `Selected event needs to have a lesson in order to be released`;
				} else if (err.error.message === 'privileges_own') {
					errorMessage = `You don't have permission to perform this action`;
				} else if (err.error.message === 'privilages_others') {
					errorMessage = `You don't have permission to perform this action`;
				} else if (err.error.message === 'privileges_assigned_students') {
					errorMessage = `You don't have permission to perform this action`;
				} else if (err.error.message === 'invalid_pin') {
					errorMessage = `Incorrect PIN number`;
				} else if (err.error.message === 'repeated_pin') {
					errorMessage = `PIN number cannot be the same as the previous`;
				} else if (err.error.message === 'repeated_password') {
					errorMessage = `Password cannot be the same as the previous`;
				} else if (err.error.message === 'flight_from_required') {
					errorMessage = `Field <strong>From</strong> is missing`;
				} else if (err.error.message === 'flight_stops_required') {
					errorMessage = `Field <strong>Stops</strong> is missing`;
				} else if (err.error.message === 'flight_to_required') {
					errorMessage = `Field <strong>To</strong> is missing`;
				} else if (err.error.message === 'no_lessons') {
					errorMessage =
						'There is at least one Stage without lessons, please complete course information or select another course.';
				} else if (err.error.message === 'user_not_active') {
					errorMessage = 'User not currently active.';
				} else if (err.error.message === 'instructor_cannot_fly') {
					errorMessage = 'Instructor cannot fly the selected equipment';
				} else if (err.error.message === 'equipment_in_use') {
					errorMessage = 'Cannot add, modify or delete credentials as <strong>Equipment is currently in use</strong>';
				} else if (err.error.message === 'instructors_cannot_fly') {
					errorMessage = 'One of the instructors cannot fly a selected equipment';
				} else if (err.error.message === 'no_reservations') {
					errorMessage =
						'There is at least one Stage without lessons, please complete course information or select another course.';
				} else if (err.error.message === 'requirement_tracking_not_applicable') {
					errorMessage =
						'The credential cannot be resolved because the <strong>Maintenance tracked by</strong> value of the equipment does not match the credential';
				} else if (err.error.message === 'instructor_cannot_grade') {
					errorMessage = `
						Instructor:
						<strong>is not allowed to do this type of reservations</strong>
						for the related course.
					`;
				} else if (err.error.message === 'instructor_cannot_teach') {
					errorMessage = 'Instructor cannot teach the course the client is enrolled in.';
				} else if (err.error.message === 'invalid_time') {
					// (time_simulation + time_actual) > (hobbs1_in - hobbs1_out)
					errorMessage = 'There is an invalid time value, please check again the entered values.';
				} else if (err.error.message === 'Server Error') {
					errorMessage = 'There has been an error on the server, please try again later.';
				} else if (err.error.message === 'out_stage') {
					errorMessage = 'Selected lesson is curently out of stage, please update the lesson.';
				} else if (err.error.message === 'client_no_course') {
					errorMessage = 'Selected client is not currently enrolled in a Course.';
				} else if (err.error.message === 'time_taken_instructor' || err.error.message === 'Instructor busy') {
					errorMessage = 'The event cannot be created/updated because the instructor is busy for another event';
				} else if (err.error.message === 'time_taken_client' || err.error.message === 'Client busy') {
					errorMessage = 'The event cannot be created/updated because the client is busy for another event';
				} else if (err.error.message === 'pending_reservation') {
					const offset = Number(new Date().getTimezoneOffset() / 60);

					errorMessage = 'There is a reservation pending to check in for this equipment';
					if (err.error.found && err.error.found.start_date) {
						const startDate = moment(err.error.found.start_date.slice(0, 16))
							.subtract(offset, 'hours')
							.format('YYYY-MM-DD HH:mm');
						errorMessage += ' on ' + startDate;
					}
					if (err.error.found && err.error.found.end_date) {
						const endDate = moment(err.error.found.end_date.slice(0, 16))
							.subtract(offset, 'hours')
							.format('YYYY-MM-DD HH:mm');
						errorMessage += ' - ' + endDate;
					}
				} else if (
					typeof err.error.message === 'string' &&
					err.error.message.indexOf('not equipment available for lesson') !== -1
				) {
					errorMessage = err.error.message.charAt(0).toUpperCase() + err.error.message.slice(1);
				} else if (
					typeof err.error.message === 'string' &&
					err.error.message.indexOf('https://ks-cta-app-svc-staging.azurewebsites.net') !== -1
				) {
					errorMessage = 'There has been an error with a CTA service';
				} else if (
					typeof err.error.message === 'string' &&
					err.error.message.indexOf(
						'<Error code=\"6240\"><Message>Duplicate Name Exists Error</Message><Detail>The name supplied already exists.',
					) !== -1
				) {
					errorMessage = '<strong>QB Error:</strong> The name supplied already exists.';
				} else if (
					typeof err.error.message === 'string' &&
					(err.error.message.indexOf('is not allowed to fly') !== -1 ||
						err.error.message.indexOf('No equipment was found according to the settings') !== -1 ||
						err.error.message.indexOf('There are no dates available for this reservation') !== -1)
				) {
					errorMessage = err.error.message;
				} else if (
					err.error.message === 'The username field is required.' ||
					err.error.message === 'The email has already been taken.' ||
					err.error.message === 'The description field is required.' ||
					err.error.message === 'The serial number has already been taken.' ||
					err.error.message === 'The email must be a valid email address.' ||
					err.error.message === 'The tail number has already been taken.'
				) {
					errorMessage = err.error.message;
				} else if (method === 'updatePicture') {
					errorMessage = 'There was an error uploading the image. Please check that your image es less than 20MB';
				} else if (method === 'getSchool()') {
					errorMessage = 'There was an error getting the school information. Please try again later.';
				} else if (method === 'getStudents()') {
					errorMessage = 'There was an error getting the students information. Please try again later.';
				} else if (method === 'getSingleKingCourse') {
					errorMessage = 'There was an error getting the selected <strong>King course</strong>. Please try again later.';
				} else if (method === 'getKingCourses') {
					errorMessage = 'There was an error getting <strong>King courses</strong>. Please try again later.';
				} else if (method === 'gradingService.getGrading()') {
					errorMessage = 'There was an error getting this <strong>grading</strong> information. Please try again later.';
				} else if (method === 'getEvent getSingleElement()') {
					errorMessage =
						'There was an error getting the <strong>selected event</strong> information. Please try again later.';
				} else if (err.error.message === 'lead_enrolled') {
					errorMessage = 'Lead is already enrolled in the course. Please review the Ninja User profile.';
				} else if (err.error.message === 'pool_taken') {
					errorMessage = 'There is already a pool created for the selected month. Please choose another month.';
				} else if (err.error.message === 'The syllabus_courses.0.duration must be at least 0.') {
					errorMessage = 'The duration must be at least 0.';
				} else if (err.error.message === "Trying to get property 'accepted' of non-object") {
					errorMessage = 'There was an error with the selected course. Please try again later.';
				} else if (
					err.error.message === 'The lead must have a quote link.' ||
					err.error.message === 'The lead must have a quote link.\\nThe lead must have at least one accepted quote.'
				) {
					errorMessage = 'The lead must have a quote link.';
				} else if (err.error.message === 'The lead must have at least one accepted quote.') {
					errorMessage = 'The lead must have at least one accepted quote.';
				} else if (
					err.error.message.includes('The duration of the course at line') ||
					err.error.message.includes('The price of the course at line')
				) {
					errorMessage = err.error.message.replace(/\\n/g, '<br>');
				} else if (err.error.message.includes('You cannot change data of a course that was previously accepted')) {
					errorMessage = err.error.message;
				} else if (err.error.message.includes('pin must be 4 digits')) {
					errorMessage = err.error.message;
				} else if (err.error.message.includes('You cannot remove a course that was previously accepted')) {
					errorMessage = err.error.message;
				} else if (err.error.message.includes('The accepted check of the course at line 1 is required')) {
					errorMessage = err.error.message;
				} else if (err.error.message.includes('The vip notes may not be greater than 255 characters')) {
					errorMessage = err.error.message;
				} else if (err.error.message.includes("Can't assign more than one course as primary")) {
					errorMessage = err.error.message;
				}
			} else if (err && err.error && err.error.requirements) {
				if (err.error.requirements === 'both_instructors_can_not_teach') {
					errorMessage = `The instructor(s) of the selected client, can't instruct the selected course.`;
				} else if (err.error.requirements === 'course_without_lessons') {
					errorMessage = `The course selected does not have lessons.`;
				} else if (
					err.error.requirements.length > 0 &&
					err.error.requirements[0].client_id &&
					err.error.requirements[0].client_id > 0
				) {
					if (err.error.requirements.some((credential) => credential.client_id && credential.client_id > 0)) {
						errorMessage = `The selected client has unmet credentials.`;
					}
				}
			} else if (err && err.error && err.error.error && err.error.error.length > 0) {
				if (err.error.error === 'course_active') {
					errorMessage = 'Client is currently on an active course';
				} else if (err.error.error === 'selected_primary_instructor_cannot_teach_current_users_course') {
					errorMessage = `The selected primary instructor cannot teach the course the client is enrolled in.`;
				} else if (err.error.error === 'client_cannot_fly') {
					errorMessage = 'Client cannot fly the selected equipment';
				} else if (err.error.error === 'instructor_cannot_fly') {
					errorMessage = 'Instructor cannot fly the selected equipment';
				} else if (err.error.error === 'instructors_cannot_fly') {
					errorMessage = 'One of the instructors cannot fly a selected equipment';
				} else if (err.error.error === 'instructor_cannot_teach') {
					errorMessage = 'Instructor cannot teach the course the client is enrolled in.';
				} else if (err.error.error === 'time_taken') {
					errorMessage = 'The event cannot be created/updated because it overlaps with another event';
				} else if (err.error.error === 'user_not_active') {
					errorMessage = 'User not currently active.';
				} else if (err.error.error === 'cancel_event_permission') {
					errorMessage = `You don't have permission to cancel this event`;
				} else if (err.error.error === 'missing_lesson') {
					errorMessage = `Selected event needs to have a lesson in order to be released`;
				} else if (err.error.error === 'privileges_own') {
					errorMessage = `You don't have permission to perform this action`;
				} else if (err.error.error === 'privilages_others') {
					errorMessage = `You don't have permission to perform this action`;
				} else if (err.error.error === 'privileges_assigned_students') {
					errorMessage = `You don't have permission to perform this action`;
				} else if (err.error.error === 'equipment_cannot_fly') {
					errorMessage = 'Selected equipment has issues/squawks pending to resolve.';
				} else if (err.error.error === 'instructor_cannot_grade') {
					errorMessage = `
						Instructor:
						<strong>is not allowed to do this type of reservations</strong>
						for the related course.
					`;
				} else if (err.error.error === 'time_taken_equipment') {
					errorMessage = 'The event cannot be created/updated because the equipment is busy for another event';
				} else if (err.error.error === 'requirement_tracking_not_applicable') {
					errorMessage =
						'The credential cannot be resolved because the <strong>Maintenance tracked by</strong> value of the equipment does not match the credential';
				} else if (err.error.error === 'time_taken_instructor' || err.error.error === 'Instructor busy') {
					errorMessage = 'The event cannot be created/updated because the instructor is busy for another event';
				} else if (err.error.error === 'time_taken_client' || err.error.error === 'Client busy') {
					errorMessage = 'The event cannot be created/updated because the client is busy for another event';
				} else if (
					typeof err.error.error === 'string' &&
					(err.error.error.indexOf('is not allowed to fly') !== -1 ||
						err.error.error.indexOf('No equipment was found according to the settings') !== -1 ||
						err.error.error.indexOf('There are no dates available for this reservation') !== -1)
				) {
					errorMessage = err.error.error;
				}
			} else if (method === 'updatePicture') {
				errorMessage = 'There was an error uploading the image. Please check that your image es less than 20MB';
			} else if (method === 'getSchool()') {
				errorMessage = 'There was an error getting the school information. Please try again later.';
			} else if (method === 'getStudents()') {
				errorMessage = 'There was an error getting the students information. Please try again later.';
			} else if (method === 'getSingleKingCourse') {
				errorMessage = 'There was an error getting the selected <strong>King course</strong>. Please try again later.';
			} else if (method === 'getKingCourses') {
				errorMessage = 'There was an error getting <strong>King courses</strong>. Please try again later.';
			} else if (method === 'gradingService.getGrading()') {
				errorMessage = 'There was an error getting this <strong>grading</strong> information. Please try again later.';
			} else if (method === 'addBalance') {
				errorMessage = 'There was an error adding Balance to user. Please try again later.';
			} else if (method === 'getLeaseback()') {
				errorMessage = 'There was an error getting the Leaseback information. Please try again later.';
			} else if (method === 'changeCourseStatus)') {
				errorMessage = 'There was an error Changing Course status. Please try again later.';
			} else if (method === 'getItems()') {
				errorMessage = 'There was an error getting Items. Please try again later.';
			} else if (method === 'showStatement()') {
				errorMessage = 'There was an error getting the statement. Please try again later.';
			} else if (method === 'confirmClientPin()') {
				errorMessage = "There was an error confirming client's pin. Please try again later.";
			} else if (method === 'exportCalendar()') {
				errorMessage = 'There was an error exporting calendar data. Please try again later.';
			} else if (method === 'getCredentialHistory') {
				errorMessage = 'There was an error getting current credential history. Please try again later.';
			} else if (method === 'getEquipmentCredentials()') {
				errorMessage = 'There was an error getting current equipment credentials. Please try again later.';
			} else if (method === 'getUserCredentials()') {
				errorMessage = 'There was an error getting current user credentials. Please try again later.';
			} else if (method === 'getTransferCreditsInputs()') {
				errorMessage = 'There was an error getting selected course information. Please try again later.';
			} else if (method === 'getCredentialLogHistory()') {
				errorMessage = 'There was an error getting selected credential log history. Please try again later.';
			} else if (method === 'gradingService.uploadLog') {
				errorMessage = 'There was an error updating the log. Please try again later.';
			} else if (method === 'getStudentDashboardInfo()') {
				errorMessage = 'There was an error getting the Dashboard information. Please try again later.';
			} else if (method === 'getEvent getSingleElement()') {
				errorMessage = 'There was an error getting the selected event information. Please try again later.';
			} else if (method === 'deleteInvoice()') {
				errorMessage = 'There was an error deleting the selected invoice. Please try again later.';
			}

			this.show(errorMessage, 'close', 'warning');
		} catch (error) {
			console.error(error);
		}
	}
}
