import moment from "moment";

export interface IBackendWorkOrderPoNote{
	id        : number;
	order_id  : number;
	created_by   : number;
	note	  : string;
	note_by   : string; //phantom variable
	created_at: Date;
	updated_at: Date;
}

export interface IWorkOrderPoNote {
	id        : number;
	orderId  : number;
	createdBy   : number;
	note     : string;
	noteBy   : string;
	createdAt: Date;
	updatedAt: Date;
}

export class WorkOrderPoNote implements IWorkOrderPoNote {
	static readonly clean = Object.freeze(new WorkOrderPoNote());
	id = 0;
	orderId = 0;
	createdBy = 0;
	note = '';
	noteBy = '';
	createdAt : Date;
	updatedAt : Date;

	parse(obj: IBackendWorkOrderPoNote) {

		const offset = Number(new Date().getTimezoneOffset() / 60);
		//this.createdAt	= 		 obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : Note.clean.createdAt;
		//this.updatedAt	= 		 obj.updated_at ? moment(obj.updated_at).subtract(offset, 'hours').toDate() : Note.clean.updatedAt;

		this.id       	= Number(obj.id           || WorkOrderPoNote.clean.id      );
		this.orderId  	= Number(obj.order_id     || WorkOrderPoNote.clean.orderId );
		this.createdBy   	= Number(obj.created_by      || WorkOrderPoNote.clean.createdBy  );
		this.note   	= String(obj.note         || WorkOrderPoNote.clean.note   );
		this.noteBy   	= String(obj.note_by      || WorkOrderPoNote.clean.noteBy  );
		this.createdAt	= obj.created_at ? moment(obj.created_at).toDate() : WorkOrderPoNote.clean.createdAt;
		this.updatedAt	= obj.updated_at ? moment(obj.updated_at).toDate() : WorkOrderPoNote.clean.updatedAt;

		return this;
	}

	set(obj: IWorkOrderPoNote) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = WorkOrderPoNote.clean[key];
		});

		return this;
	}

	clone() {
		return new WorkOrderPoNote().set(this);
	}
}
